import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import SEO from "../components/seo";
import styles from "./hotelDetail.module.scss";
import ArrangementCard from "./ArrangementCard";
import ContactIcons from "../components/ContactIcons";
import SocialMedia from "../components/SocialMedia";
import Img from "gatsby-image";
import CloudinaryImage from "../components/CloudinaryImage/index";
import PropTypes from "prop-types";

export default function HotelDetail({ data }) {
  const {slug} = data.markdownRemark.fields;
  const {markdownRemark} = data;

  const hotelData = markdownRemark.frontmatter;


  const {
    name,
    description,
    shortDescription,
    phone,
    email,
    website,
    logo,
    hero,
  } = hotelData;

  const { arrangements } = data;


  const heroSrc =
    hero &&
    hero.childImageSharp;



  return (
    <Layout>
      <SEO title={name} />
      <Card className={styles.card}>
        {/* kevin cloudinary */}
        {heroSrc && (
          // <Img
          //   loading="lazy"
          //   title={name}
          //   alt={`Bild: ${name}`}
          //   fluid={hero.childImageSharp.fluid}
          //   className={styles.hero}
          // />
          <CloudinaryImage
            base64={hero.childImageSharp.fluid.base64}
            aspectRatio={hero.childImageSharp.fluid.aspectRatio}
            url={hero.publicURL}
            // http://cdn.sanity.io/static/2cc9cf5676efd05becf1e126570168bc/kurzurlaub-am-niederrhein-6-tage.jpg
            sizes={"(min-width: 40em) 50vw, (min-width: 62em) 35vw, (min-width: 106em) 25vw, 100vw"}
            className={styles.hero}
          />
        )}
        <CardContent className={styles.cardContent}>
          {logo && (
            <div className={styles.logoWrapper}>
              <Img
                loading="lazy"
                title={name}
                alt={`Logo ${name}`}
                fixed={logo.childImageSharp.fixed}
                className={styles.logo}
              />

            </div>
          )}
          <div className={styles.infoWrapper}>
            <h1 className={styles.title}>{name}</h1>
            {(description || shortDescription) && (
              <div className={styles.description}>
                {description || shortDescription}
              </div>
            )}

            <div
              dangerouslySetInnerHTML={{
                __html: markdownRemark.html
              }}
            />

            {(phone || email || website) && (
              <ContactIcons
                phone={phone}
                email={email}
                website={website}
                hotelName={name}
              />
            )}
          <SocialMedia slug={slug} title={name} />
          </div>
        </CardContent>
      </Card>

      {/* <div dangerouslySetInnerHTML={{ __html: details.html }} /> */}
      <Grid container spacing={3}>
        {arrangements.nodes.map((node, i) => {
          return (
            <Grid key={i} item xs={12} md={6}>
              <ArrangementCard
                node={node}
                isVisible={true}
                hotelData={hotelData}
                slug={slug}
              />
            </Grid>
          );
        })}
      </Grid>
    </Layout>
  );
}

HotelDetail.propTypes = {
  data: PropTypes.object,
};

{/* kevin cloudinary : publicURL*/}
export const query = graphql`
  query($slug: String!, $key: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        shortDescription
        description
        email
        contactPerson
        contactEmail
        phone
        website
        hero {
          publicURL
          name
          childImageSharp {
            fluid {
              base64
              aspectRatio
            }
          }
        }
        logo {
          childImageSharp {
            fixed(height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      fields {
        slug
      }
    }
    arrangements: allFile(
      filter: {
        sourceInstanceName: { eq: "arrangements" }
        childMarkdownRemark: { frontmatter: { key: { eq: $key } } }
        extension: { eq: "md" }
      }
      sort: { fields: childMarkdownRemark___frontmatter___order, order: ASC }
    ) {
      totalCount
      nodes {
        childMarkdownRemark {
          html
          frontmatter {
            title
            key
            teaserText
            description
            copyright
            arrangementHero {
              publicURL
              name
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                }
              }
            }
            url
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
