import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Button } from "@material-ui/core";
import styles from "./arrangementCard.module.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";
import * as classNames from "classnames";
import ContactIcons from "../components/ContactIcons";
// import Img from "gatsby-image";
import SocialMedia from "../components/SocialMedia";
import CloudinaryImage from "../components/CloudinaryImage/index";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ArrangementCard = ({ node, isVisible, hotelData, slug }) => {
  const [open, setOpen] = React.useState(false);
  const { childMarkdownRemark } = node;
  const { frontmatter } = childMarkdownRemark;
  const { name, email, contactEmail, contactPerson, website, phone } = hotelData;
  const {
    title,
    description,
    arrangementHero,
    url,
    teaserText,
    copyright
  } = frontmatter;

  const dialogContentClassName = classNames(styles.dialogContent, {
    [styles.noImage]: !arrangementHero
  });

  const displayEmail = contactEmail || email;
  const displayUrl = url || website;
  const showContactBox = contactPerson || displayEmail || displayUrl;

  const handleClose = () => {
    // setOpen(false)
    window.location.hash = "";
  };

  // const test = bar
  // test = xxx

  const handleClickOpen = e => {
    // setOpen(true)
    e.preventDefault();
    var scr = document.body.scrollTop;
    window.location.hash = `#${encodeURIComponent(title)}`;
    document.body.scrollTop = scr;
    return false;
  };

  const locationHashChanged = () => {
    const hash = window.location.href.split("#")[1];
    const isOpen = hash === encodeURIComponent(title);
    setOpen(isOpen);
  };

  useEffect(() => {
    window.addEventListener("hashchange", locationHashChanged, false);
    locationHashChanged();

    return () => {
      window.removeEventListener("hashchange", locationHashChanged, false);
    };
  }, []);

  return (
    <>
      <Grow in={isVisible}>
        <Card>
          {arrangementHero && arrangementHero.childImageSharp && (
            <CardActionArea onClick={handleClickOpen}>
              {/* <Img
                loading="lazy"
                title={title}
                alt={`Arragement Bild: ${title}`}
                fluid={arrangementHero.childImageSharp.fluid}
                className={styles.hero}
              /> */}
              {/* kevin cloudinary */}
              <CloudinaryImage
                base64={arrangementHero.childImageSharp.fluid.base64}
                aspectRatio={arrangementHero.childImageSharp.fluid.aspectRatio}
                url={arrangementHero.publicURL}
                // http://cdn.sanity.io/static/2cc9cf5676efd05becf1e126570168bc/kurzurlaub-am-niederrhein-6-tage.jpg
                sizes={"(min-width: 40em) 50vw, (min-width: 62em) 35vw, (min-width: 106em) 25vw, 100vw"}
                className={styles.hero}
              />
            </CardActionArea>
          )}

          <CardContent className={styles.content}>
            <h1>{node.childMarkdownRemark.frontmatter.title}</h1>
            <p>{teaserText || description}</p>
          </CardContent>
          <CardActions disableSpacing className={styles.actionBar}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
              size="small"
              className={styles.button}
            >
              Details
            </Button>
          </CardActions>
        </Card>
      </Grow>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
      >
        <h1 className={styles.dialogTitle}>
          {title}
          <SocialMedia slug={`${slug}#${encodeURIComponent(title)}`} title={title}  />

        </h1>
        <DialogContent dividers className={dialogContentClassName}>
          {arrangementHero && arrangementHero.childImageSharp && (
            <>
              {/* <Img
                loading="lazy"
                title={title}
                alt={`Arragement Bild: ${title}`}
                fluid={arrangementHero.childImageSharp.fluid}
                className={styles.dialogHero}
              /> */}
              {/* REPLACEME */}
              {/* https://res.cloudinary.com/ACCOUNT-ID/image/fetch/c_limit,f_auto,w_40/ */}
              {/* kevin cloudinary */}
              <CloudinaryImage
                base64={arrangementHero.childImageSharp.fluid.base64}
                aspectRatio={arrangementHero.childImageSharp.fluid.aspectRatio}
                url={arrangementHero.publicURL}
                // http://cdn.sanity.io/static/2cc9cf5676efd05becf1e126570168bc/kurzurlaub-am-niederrhein-6-tage.jpg
                sizes={"(min-width: 40em) 50vw, (min-width: 62em) 35vw, (min-width: 106em) 25vw, 100vw"}
                className={styles.dialogHero}
              />
            {/* <pre>{JSON.stringify(arrangementHero, null, 2)}</pre> */}
              {copyright && (
                <small
                  style={{
                    textAlign: "right",
                    display: "inherit",
                    marginBottom: 20
                  }}
                >
                  {copyright}
                </small>
              )}
            </>
          )}
          <div>
            <div>{description || teaserText}</div>
            <br />

            <div
              dangerouslySetInnerHTML={{
                __html: node.childMarkdownRemark.html
              }}
            />
            {showContactBox && (
              <div className={styles.contactBox}>
                {contactPerson && (
                  <div>
                    <strong>Ansprechpartner: </strong>
                    {contactPerson}
                  </div>
                )}
                {/* {displayEmail && (
                <div>
                  <strong>Email: </strong>
                  <Email
                    email={displayEmail}
                    hotelName={name}
                    arrangementName={title}
                  />
                </div>
              )} */}
                <div className={styles.contactBox__actionbar}>
                  <Button
                    variant="contained"
                    color="primary"
                    href={displayUrl}
                    title="Jetzt buchen"
                    target="_blank"
                    className={styles.ctaButton}
                  >
                    Jetzt buchen
                  </Button>
                  {(phone || email || displayUrl) && (
                    <ContactIcons
                      phone={phone}
                      email={displayEmail}
                      website={displayUrl}
                      hotelName={name}
                      arrangementName={title}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={styles.button}
          >
            Schliessen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ArrangementCard.propTypes = {
  node: PropTypes.object,
  isVisible: PropTypes.bool,
  hotelData: PropTypes.object,
  slug: PropTypes.string,
};

export default ArrangementCard;
