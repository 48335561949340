import React from "react"
import FacebookIcon from "@material-ui/icons/Facebook"
import { REISEZIEL_URL } from "../config"
import { IconButton } from "@material-ui/core"
import styles from "./socialMedia.module.scss"

const SocialMedia = ({ slug, title }) => {
  const shareUrl = encodeURIComponent(`${REISEZIEL_URL}${slug}`)
  const shareTitle = encodeURIComponent(title)
  const url = `http://www.facebook.com/share.php?u=${shareUrl}&t=${shareTitle}`
  return (
    <IconButton aria-label="share" href={url} target="_blank">
      <FacebookIcon className={styles.icon} />
    </IconButton>
  )
}

export default SocialMedia

